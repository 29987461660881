import s from "./MainImage.module.scss";
import React from "react";

export default function MainImage() {
  return (
    <main className={s.container}>
      <div className={s.imageWrapper}>
        <div className={s.contentWrapper2}>
          <div className={s.messageWrapper}>
            <div>“삼가 고인의 명복을 빕니다.”</div>
            <div>한 분 한 분 정성을 다해 모시겠습니다.</div>
          </div>
          <div className={s.btnContainer}>
            <div className={s.btnWrapper}>
              <div
                className={s.first}
                onClick={() => (window.location.href = "tel:0522549702")}
              >
                <div className={s.iconWrapper}></div>
                <div className={s.stringWrapper}>
                  <div className={s.comment}>
                    장례상담 및 장례식장 이용 문의 <br></br> (24시간) 전화
                  </div>
                  <div className={s.numberWrapper}>
                    <div className={s.numberIcon}></div>
                    <div className={s.number}>052) 254-9702</div>
                  </div>
                </div>
              </div>
              <div
                className={s.two}
                onClick={() => (window.location.href = "tel:0522641017")}
              >
                <div className={s.iconWrapper2}></div>
                <div className={s.stringWrapper}>
                  <div className={s.comment}>
                    제사(49상, 기제사) 예약 및 화환<br></br>
                    주문 전화 (07:00~16:00)
                  </div>
                  <div className={s.numberWrapper}>
                    <div className={s.numberIcon}></div>
                    <div className={s.number}>052) 264-1017</div>
                  </div>
                </div>
              </div>
              <div
                className={s.three}
                onClick={() => (window.location.href = "tel:01097743303")}
              >
                <div className={s.iconWrapper3}></div>
                <div className={s.stringWrapper}>
                  <div className={s.comment}>
                    봉안함, 위패, 자연장지(잔디장), 개인위패<br></br>
                    상담전화 (07:00~16:00)<br></br>
                  </div>
                  <div className={s.numberWrapper}>
                    <div className={s.numberIcon}></div>
                    <div className={s.number}>010-9774-3303</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
